<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
          <div class="iq-card">
            <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Comment Lists</h4>
                </div>
            </div>
            <div class="iq-card-body">
                <div class="table-responsive">
                  <table class="data-tables table movie_table" style="width:100%">
                      <thead>
                        <tr>
                            <th style="width: 10%;">No</th>
                            <th style="width: 20%;">Title</th>
                            <th style="width: 10%;">Author</th>
                            <th style="width: 25%;">Description</th>
                            <th style="width: 15%;">Created Date</th>
                            <th style="width: 10%;">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td>1</td>
                            <td>
                              Lorem ipsum dolor
                            </td>
                            <td>Nick Allen</td>
                            <td>
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus non elit a scelerisque. Etiam feugiat luctus est, vel commodo odio rhoncus sit amet</p>
                            </td>
                            <td>
                              21 Jul, 2020
                            </td>
                            <td>
                              <div class="flex align-items-center list-user-action">
                                  <a class="iq-bg-warning" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="View" href="#"><i class="lar la-eye"></i></a>
                                  <a class="iq-bg-success" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" href="#"><i
                                        class="ri-pencil-line"></i></a>
                                  <a class="iq-bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" href="#"><i
                                        class="ri-delete-bin-line"></i></a>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>
                              Lorem ipsum dolor
                            </td>
                            <td>Hans Olo</td>
                            <td>
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus non elit a scelerisque. Etiam feugiat luctus est, vel commodo odio rhoncus sit amet</p>
                            </td>
                            <td>
                              12 Jun, 2020
                            </td>
                            <td>
                              <div class="flex align-items-center list-user-action">
                                  <a class="iq-bg-warning" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="View" href="#"><i class="lar la-eye"></i></a>
                                  <a class="iq-bg-success" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" href="#"><i
                                        class="ri-pencil-line"></i></a>
                                  <a class="iq-bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" href="#"><i
                                        class="ri-delete-bin-line"></i></a>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>
                              Lorem ipsum dolor
                            </td>
                            <td>Lynn Guini</td>
                            <td>
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus non elit a scelerisque. Etiam feugiat luctus est, vel commodo odio rhoncus sit amet</p>
                            </td>
                            <td>
                              01 Jan, 2020
                            </td>
                            <td>
                              <div class="flex align-items-center list-user-action">
                                  <a class="iq-bg-warning" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="View" href="#"><i class="lar la-eye"></i></a>
                                  <a class="iq-bg-success" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" href="#"><i
                                        class="ri-pencil-line"></i></a>
                                  <a class="iq-bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" href="#"><i
                                        class="ri-delete-bin-line"></i></a>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>
                              Lorem ipsum dolor
                            </td>
                            <td>aaronottix</td>
                            <td>
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus non elit a scelerisque. Etiam feugiat luctus est, vel commodo odio rhoncus sit amet</p>
                            </td>
                            <td>
                              19 Mar, 2020
                            </td>
                            <td>
                              <div class="flex align-items-center list-user-action">
                                  <a class="iq-bg-warning" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="View" href="#"><i class="lar la-eye"></i></a>
                                  <a class="iq-bg-success" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" href="#"><i
                                        class="ri-pencil-line"></i></a>
                                  <a class="iq-bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" href="#"><i
                                        class="ri-delete-bin-line"></i></a>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>
                              Lorem ipsum dolor
                            </td>
                            <td>Marge Arita</td>
                            <td>
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus non elit a scelerisque. Etiam feugiat luctus est, vel commodo odio rhoncus sit amet</p>
                            </td>
                            <td>
                              21 Aug, 2020
                            </td>
                            <td>
                              <div class="flex align-items-center list-user-action">
                                  <a class="iq-bg-warning" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="View" href="#"><i class="lar la-eye"></i></a>
                                  <a class="iq-bg-success" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" href="#"><i
                                        class="ri-pencil-line"></i></a>
                                  <a class="iq-bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" href="#"><i
                                        class="ri-delete-bin-line"></i></a>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>
                              Lorem ipsum dolor
                            </td>
                            <td>Bill Dabear</td>
                            <td>
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus non elit a scelerisque. Etiam feugiat luctus est, vel commodo odio rhoncus sit amet</p>
                            </td>
                            <td>
                              15 May, 2020
                            </td>
                            <td>
                              <div class="flex align-items-center list-user-action">
                                  <a class="iq-bg-warning" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="View" href="#"><i class="lar la-eye"></i></a>
                                  <a class="iq-bg-success" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" href="#"><i
                                        class="ri-pencil-line"></i></a>
                                  <a class="iq-bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" href="#"><i
                                        class="ri-delete-bin-line"></i></a>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>
                              Lorem ipsum dolor
                            </td>
                            <td>Brock Lee</td>
                            <td>
                              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus non elit a scelerisque. Etiam feugiat luctus est, vel commodo odio rhoncus sit amet</p>
                            </td>
                            <td>
                              07 Jul, 2020
                            </td>
                            <td>
                              <div class="flex align-items-center list-user-action">
                                  <a class="iq-bg-warning" data-toggle="tooltip" data-placement="top" title=""
                                  data-original-title="View" href="#"><i class="lar la-eye"></i></a>
                                  <a class="iq-bg-success" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" href="#"><i
                                        class="ri-pencil-line"></i></a>
                                  <a class="iq-bg-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" href="#"><i
                                        class="ri-delete-bin-line"></i></a>
                              </div>
                            </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
            </div>
          </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
export default {
  name: 'Comment',
  mounted () {
    core.index()
    core.initDataTable()
  },
  methods: {
  },
  data () {
    return {
    }
  }
}
</script>
